import { useStoreActions, useStoreState } from "easy-peasy";
import LoginPage from "./LoginPage";
import Page from "./Page";
import { useEffect } from "react";
import { useIsFocused } from "@react-navigation/native";

function ProtectedPage({ children, ...props }) {
  const { user, azureUser } = useStoreState((state) => state.user);

  const isFocused = useIsFocused();

  const { getUserThunk } = useStoreActions((actions) => actions);

  useEffect(() => {
    isFocused && getUserThunk();
  }, [isFocused]);

  return (
    <Page {...props}>
      {user.email && azureUser.email ? children : <LoginPage />}
    </Page>
  );
}

export default ProtectedPage;
