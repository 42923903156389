import { axios } from '.';

const expenseAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_ADMIN_JWT}`,
  },
});

async function getExpensesAPICall(expenseDays) {
  return expenseAxios({
    method: 'get',
    url: `/api/v1/expenses`,
  });
}

async function getTimeSheets() {
  return expenseAxios({
    method: 'get',
    url: `/api/v1/timeSheet`,
  });
}

export { expenseAxios, getExpensesAPICall, getTimeSheets };
