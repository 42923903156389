import { View } from "react-native";
import Col from "../../presentational/Col";
import Row from "../../presentational/Row";
import Text from "../../presentational/Text";
import Image from "../../presentational/Image";
import styled from "rn-css";
import { useStoreState } from "easy-peasy";

const Container = styled(Row)`
  width: 100%;
  justify-content: space-between;
`;
const SText = styled(Text)`
  text-align: right;

  @media (max-width: 576px) {
    display: none;
  }
`;

function DrawerHeader() {
  const { username } = useStoreState((state) => state.user.user);

  return (
    <Container>
      <Col xs={0} sm={3} />
      <Col xs={0} sm={4}>
        <Image name="favicon.png" height="80%" width="80%" />
      </Col>
      <Col xs={0} sm={5}>
        <View>
          <SText>Welcome to CFBS Acumatica Admin App</SText>
          <SText>{username}</SText>
        </View>
      </Col>
    </Container>
  );
}

export default DrawerHeader;
