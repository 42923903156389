import { StoreProvider } from "easy-peasy";
import store from "./src/store";
import AppNavigator from "./src/components/navigation/AppNavigator";
import { axiosInterceptor } from "./src/utilities/api";
import { authAxios } from "./src/api/auth";
import { expenseAxios } from "./src/api/expense";
import { invoiceAxios } from "./src/api/invoice";
import { userAxios } from "./src/api/user";

export default function App() {
  axiosInterceptor(authAxios);
  axiosInterceptor(expenseAxios);
  axiosInterceptor(invoiceAxios);
  axiosInterceptor(userAxios);

  return (
    <StoreProvider store={store}>
      <AppNavigator />
    </StoreProvider>
  );
}
