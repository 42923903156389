import { createStore } from "easy-peasy";
import statusStore from "./status";
import authStore from "./auth";
import invoiceStore from "./invoice";
import expenseStore from "./expense";
import userStore from "./user";

const store = createStore({
  ...statusStore,
  ...authStore,
  ...invoiceStore,
  ...expenseStore,
  ...userStore,
});

export default store;
