const defaultState = {
  user: {
    email: "",
    username: "",
  },
  azureUser: {
    email: "",
    username: "",
  },
};

const state = {
  user: defaultState,
};

export { state as default, defaultState };
