import { axios } from ".";

const authAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/auth`,
});

function loginAPICall(data) {
  return authAxios({ method: "post", url: "/login", data });
}

function refreshTokenAPICall() {
  return authAxios({ method: "post", url: "/token/refresh" });
}

export { authAxios, loginAPICall, refreshTokenAPICall };
