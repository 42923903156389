import { thunk } from 'easy-peasy';
import { getExpensesAPICall, getTimeSheets } from '../../api/expense';

const thunks = {
  getExpensesThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      await getExpensesAPICall(helpers.getState().expense.expenseDays || null);
    } catch (err) {
      console.log('failed');
      actions.setAlertThunk({
        type: 'error',
        message: 'Failed to get expenses',
      });
    }

    actions.setLoadingAction(false);
  }),
  getTimesheetsThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      await getTimeSheets();
    } catch (err) {
      console.log('failed');
      actions.setAlertThunk({
        type: 'error',
        message: 'Failed to get Timesheets',
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
