import { View, CheckBox } from 'react-native';
import styled from 'styled-components/native';
import Page from '../containers/Page';
import React from 'react';
import Center from '../presentational/Center';
import Row from '../presentational/Row';
import Col from '../presentational/Col';
import Button from '../containers/Button';
import Input from '../presentational/Input';
import Gap from '../presentational/Gap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Card from '../presentational/Card';

const Container = styled(View)`
  padding: 2vh 4vw;
`;

const Header = styled.Text`
  font-size: ${(props) => (props.subtitle ? 1.5 : 2.5)}rem;
  font-weight: 500;
`;

const CheckboxLabel = styled.Text`
  color: rgb(0, 32, 91);
  margin-bottom: 15px;
`;

export default function Home() {
  const { invoice, expense } = useStoreState((state) => state);
  const { setExpenseAction, setInvoiceAction } = useStoreActions(
    (actions) => actions
  );

  const showConnectwise = process.env.REACT_APP_SHOW_CONNECTWISE === 'true';
  const showClarizen = process.env.REACT_APP_SHOW_CLARIZEN === 'true';

  return (
    <Page.Protected>
      <Container>
        {showConnectwise && (
          <Card>
            <Center>
              <Header>Connectwise</Header>
              <Header subtitle>Sync Invoices and Expenses</Header>
            </Center>

            <Gap />

            <Row>
              <Col xs={12} md={6}>
                <Center>
                  <Button.GetInvoices />
                </Center>
              </Col>

              <Col xs={12} md={6}>
                <Center>
                  <Button.GetExpenses />
                </Center>
              </Col>

              <Col xs={12} md={3}>
                <Input
                  label='Invoice #'
                  onChangeText={(invoiceNo) => setInvoiceAction({ invoiceNo })}
                  placeholder='Enter invoice #'
                  value={invoice.invoiceNo}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  label='Invoice History (days)'
                  onChangeText={(invoiceDays) =>
                    setInvoiceAction({ invoiceDays })
                  }
                  placeholder='Enter invoice history in days'
                  value={invoice.invoiceDays}
                />
              </Col>
              <Col xs={12} md={3}>
                <Center>
                  <CheckboxLabel>Use Mapped Record</CheckboxLabel>
                  <CheckBox
                    value={expense.mappedRecords}
                    onValueChange={() =>
                      setExpenseAction({
                        mappedRecords: !expense.mappedRecords,
                      })
                    }
                  />
                </Center>
              </Col>
              <Col xs={12} md={3}>
                <Input
                  label='Expense History (days)'
                  onChangeText={(expenseDays) =>
                    setExpenseAction({ expenseDays })
                  }
                  placeholder='Enter expense history in days'
                  value={expense.expenseDays}
                />
              </Col>
            </Row>
          </Card>
        )}

        {showConnectwise && showClarizen && (
          <>
            <Gap />
            <Gap />
          </>
        )}

        {showClarizen && (
          <Card>
            <Center>
              <Header>Clarizen</Header>
              <Header subtitle>Sync Invoices and Expenses</Header>
            </Center>

            <Gap />

            <Row>
              <Col xs={12} md={6}>
                <Center>
                  <Button.GetTimeSheets />
                </Center>
              </Col>

              <Col xs={12} md={6}>
                <Center>
                  <Button.GetExpenses />
                </Center>
              </Col>

              {/* <Col xs={12} md={3}>
                <Input
                  label="Invoice #"
                  onChangeText={(invoiceNo) => setInvoiceAction({ invoiceNo })}
                  placeholder="Enter invoice #"
                  value={invoice.invoiceNo}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  label="Invoice History (days)"
                  onChangeText={(invoiceDays) =>
                    setInvoiceAction({ invoiceDays })
                  }
                  placeholder="Enter invoice history in days"
                  value={invoice.invoiceDays}
                />
              </Col>
              <Col xs={12} md={3}>
                <Center>
                  <CheckboxLabel>Use Mapped Record</CheckboxLabel>
                  <CheckBox
                    value={expense.mappedRecords}
                    onValueChange={() =>
                      setExpenseAction({
                        mappedRecords: !expense.mappedRecords,
                      })
                    }
                  />
                </Center>
              </Col>
              <Col xs={12} md={3}>
                <Input
                  label="Expense History (days)"
                  onChangeText={(expenseDays) =>
                    setExpenseAction({ expenseDays })
                  }
                  placeholder="Enter expense history in days"
                  value={expense.expenseDays}
                />
              </Col> */}
            </Row>
          </Card>
        )}
      </Container>
    </Page.Protected>
  );
}
