import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
  setUserAction: action((state, user) => {
    state.user.user = {
      ...state.user.user,
      ...user,
    };
  }),
  setAzureUserAction: action((state, user) => {
    state.user.azureUser = {
      ...state.user.azureUser,
      ...user,
    };
  }),
  resetUserAction: action((state) => {
    state.user.user = defaultState.user;
  }),
};

export default actions;
