import { thunk } from "easy-peasy";
import { loginAPICall } from "../../api/auth";
import { removeAuthToken, setAuthToken } from "../../utilities/api";

const thunks = {
  loginThunk: thunk(async (actions, input) => {
    actions.setLoadingAction(true);

    try {
      const response = await loginAPICall(input);
      await setAuthToken(response.data);
      await actions.getUserThunk();
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  logoutThunk: thunk(async (actions, navigation) => {
    await removeAuthToken();
    actions.resetUserAction();
    navigation && navigation.closeDrawer();
  }),
};

export default thunks;
