import { useStoreActions } from 'easy-peasy';
import Button from '../../presentational/Button';
import styled from 'styled-components/native';

const Container = styled(Button)`
  background-color: #198754;
`;

function GetTimeSheetButton({ children = 'Sync Time Sheets', ...props }) {
  const { getTimesheetsThunk } = useStoreActions((actions) => actions);
  return (
    <Container onPress={() => getTimesheetsThunk()} {...props}>
      {children}
    </Container>
  );
}

export default GetTimeSheetButton;
