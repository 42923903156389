import { thunk } from "easy-peasy";
import { PublicClientApplication } from "@azure/msal-browser";
import { getUserAPICall } from "../../api/user";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const thunks = {
  getUserThunk: thunk(async (actions) => {
    actions.setLoadingAction(true);

    try {
      const response = await getUserAPICall();
      actions.setUserAction(response.data);

      const loginResponse = await msalInstance.loginPopup();
      actions.setAzureUserAction({
        username: loginResponse.account.name,
        email: loginResponse.account.username,
      });
    } catch (err) {
      console.log(err);
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
