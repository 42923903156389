import { axios } from ".";

const userAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/user`,
});

function getUserAPICall() {
  return userAxios({ method: "get", url: "/get" });
}

export { userAxios, getUserAPICall };
